import React, { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import App from './layouts/App';
import Loader from './layouts/layoutcomponents/loader';
import { RouterData } from './routes/routingdata';

import './i18n';
import './Init';
import './index.scss';

const Auth = lazy(() => import('./pages/auth/Auth'));
const AuthLogin = lazy(() => import('./pages/auth/LoginPage'));
const AuthForgotPasword = lazy(() => import('./pages/auth/ForgotPasswordPage'));
const AuthResetPassword = lazy(() => import('./pages/auth/ResetPasswordPage'));
const AccountConfirm = lazy(() => import('./pages/auth/AccountConfirmPage'));
const Error404 = lazy(() => import('./pages/error404/error404'));

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import '@/assets/themes/bootstrap4-light-blue/theme_new.css';

export const Index = () => {

  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>

        {/* Authentication Routes */}
        <Route path={`${import.meta.env.BASE_URL}auth`} element={<Auth />}>
          <Route index element={<AuthLogin />} />
          <Route path={`${import.meta.env.BASE_URL}auth/forgot-password`} element={<AuthForgotPasword />} />
          <Route path={`${import.meta.env.BASE_URL}auth/reset-password`} element={<AuthResetPassword />} />
          <Route path={`${import.meta.env.BASE_URL}auth/account-confirm`} element={<AccountConfirm />} />
        </Route>

        {/* Protected Routes */}
        {RouterData.map((idx) => (
          <Route path={`${import.meta.env.BASE_URL}`} element={<App />} key={Math.random()}>
            <Route path={idx.path} element={idx.element} />
            <Route path={`${import.meta.env.BASE_URL}*`} element={<Error404 />} />
          </Route>
        ))};

        {/* Landing */}
        {/* <Route path="/" element={<Landing />}>
          <Route path={`${import.meta.env.BASE_URL}landingpage`} element={<LandingPage />} />
        </Route> */}

        {/* Custom Switcher Pages */}
        {/* <Route path="/" element={<CustomSwitcher />}>
          <Route path={`${import.meta.env.BASE_URL}pages/switcher`} element={<Switcher />} />
        </Route> */}

        {/* Custom Authentication Pages */}
        {/* <Route path="/" element={<CustomAuthenticationPages />}>
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/signin`} element={<SignIn />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/signup`} element={<SignUp />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/forgotpassword`} element={<ForgotPassword />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/resetpassword`} element={<ResetPassword />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/lockscreen`} element={<LockScreen />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/underconstruction`} element={<UnderConstruction />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/error404`} element={<Error404 />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/error500`} element={<Error500 />} />
          <Route path={`${import.meta.env.BASE_URL}*`} element={<Error404 />} />
        </Route> */}
      </Routes>

    </React.Suspense>
  )
};

export default Index;